export const POST_STUDENT_REQUEST = "POST_STUDENT_REQUEST";
export const POST_STUDENT_SUCCESS = "POST_STUDENT_SUCCESS";
export const POST_STUDENT_FALIURE = "POST_STUDENT_FALIURE";

export const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FALIURE = "GET_STUDENT_FALIURE";

export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FALIURE = "DELETE_STUDENT_FALIURE"


export const UPDATE_STUDENT_REQUEST = "UPDATE_STUDENT_REQUEST";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FALIURE = "UPDATE_STUDENT_FALIURE";


export const CHECK_STUDENT_EMAIL_REQUEST = "CHECK_STUDENT_EMAIL_REQUEST";
export const CHECK_STUDENT_EMAIL_SUCCESS = "CHECK_STUDENT_EMAIL_SUCCESS";
export const CHECK_STUDENT_EMAIL_FALIURE = "CHECK_STUDENT_EMAIL_FALIURE";