export const POST_ASSESSMENTGROUP_REQUEST = "POST_ASSESSMENTGROUP_REQUEST";
export const POST_ASSESSMENTGROUP_SUCCESS = "POST_ASSESSMENTGROUP_SUCCESS";
export const POST_ASSESSMENTGROUP_FALIURE = "POST_ASSESSMENTGROUP_FALIURE";

export const GET_ASSESSMENTGROUP_REQUEST = "GET_ASSESSMENTGROUP_REQUEST";
export const GET_ASSESSMENTGROUP_SUCCESS = "GET_ASSESSMENTGROUP_SUCCESS";
export const GET_ASSESSMENTGROUP_FALIURE = "GET_ASSESSMENTGROUP_FALIURE";

export const DELETE_ASSESSMENTGROUP_REQUEST = "DELETE_ASSESSMENTGROUP_REQUEST";
export const DELETE_ASSESSMENTGROUP_SUCCESS = "DELETE_ASSESSMENTGROUP_SUCCESS";
export const DELETE_ASSESSMENTGROUP_FALIURE = "DELETE_ASSESSMENTGROUP_FALIURE";

export const UPDATE_ASSESSMENTGROUP_REQUEST = "UPDATE_ASSESSMENTGROUP_REQUEST";
export const UPDATE_ASSESSMENTGROUP_SUCCESS = "UPDATE_ASSESSMENTGROUP_SUCCESS";
export const UPDATE_ASSESSMENTGROUP_FALIURE = "UPDATE_ASSESSMENTGROUP_FALIURE";