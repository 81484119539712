export const POST_MEDICATION_REQUEST = "POST_MEDICATION_REQUEST";
export const POST_MEDICATION_SUCCESS = "POST_MEDICATION_SUCCESS";
export const POST_MEDICATION_FALIURE = "POST_MEDICATION_FALIURE";

export const GET_MEDICATION_REQUEST = "GET_MEDICATION_REQUEST";
export const GET_MEDICATION_SUCCESS = "GET_MEDICATION_SUCCESS";
export const GET_MEDICATION_FALIURE = "GET_MEDICATION_FALIURE";

export const GET_MEDECATION_FORM_DATA_REQUEST = "GET_MEDECATION_FORM_DATA_REQUEST";
export const GET_MEDECATION_FORM_DATA_SUCCESS = "GET_MEDECATION_FORM_DATA_SUCCESS";
export const GET_MEDECATION_FORM_DATA_FALIURE = "GET_MEDECATION_FORM_DATA_FALIURE";
export const APPEND_CUSTOM_META_DATA = "APPEND_CUSTOM_META_DATA";

export const DELETE_MEDICATION_REQUEST = "DELETE_MEDICATION_REQUEST";
export const DELETE_MEDICATION_SUCCESS = "DELETE_MEDICATION_SUCCESS";
export const DELETE_MEDICATION_FALIURE = "DELETE_MEDICATION_FALIURE";

export const UPDATE_MEDICATION_REQUEST = "UPDATE_MEDICATION_REQUEST";
export const UPDATE_MEDICATION_SUCCESS = "UPDATE_MEDICATION_SUCCESS";
export const UPDATE_MEDICATION_FALIURE = "UPDATE_MEDICATION_FALIURE";

export const POST_CUSTOMMEDICATIONROUTE_REQUEST = "POST_CUSTOMMEDICATIONROUTE_REQUEST";
export const POST_CUSTOMMEDICATIONROUTE_SUCCESS = "POST_CUSTOMMEDICATIONROUTE_SUCCESS";
export const POST_CUSTOMMEDICATIONROUTE_FALIURE = "POST_CUSTOMMEDICATIONROUTE_FALIURE";

export const GET_MEDICATIONMEDICINE_REQUEST = "GET_MEDICATIONMEDICINE_REQUEST";
export const GET_MEDICATIONMEDICINE_SUCCESS = "GET_MEDICATIONMEDICINE_SUCCESS";
export const GET_MEDICATIONMEDICINE_FALIURE = "GET_MEDICATIONMEDICINE_FALIURE";