export const POST_FAMILY_REQUEST = "POST_FAMILY_REQUEST";
export const POST_FAMILY_SUCCESS = "POST_FAMILY_SUCCESS";
export const POST_FAMILY_FALIURE = "POST_FAMILY_FALIURE";

export const GET_FAMILY_REQUEST = "GET_FAMILY_REQUEST";
export const GET_FAMILY_SUCCESS = "GET_FAMILY_SUCCESS";
export const GET_FAMILY_FALIURE = "GET_FAMILY_FALIURE";

export const DELETE_FAMILY_REQUEST = "DELETE_FAMILY_REQUEST";
export const DELETE_FAMILY_SUCCESS = "DELETE_FAMILY_SUCCESS";
export const DELETE_FAMILY_FALIURE = "DELETE_FAMILY_FALIURE";

export const UPDATE_FAMILY_REQUEST = "UPDATE_FAMILY_REQUEST";
export const UPDATE_FAMILY_SUCCESS = "UPDATE_FAMILY_SUCCESS";
export const UPDATE_FAMILY_FALIURE = "UPDATE_FAMILY_FALIURE";

export const GET_FAMILY_HISTORY_TYPE_REQUEST = "GET_FAMILY_HISTORY_TYPE_REQUEST";
export const GET_FAMILY_HISTORY_TYPE_SUCCESS = "GET_FAMILY_HISTORY_TYPE_SUCCESS";
export const GET_FAMILY_HISTORY_TYPE_FALIURE = "GET_FAMILY_HISTORY_TYPE_FALIURE";