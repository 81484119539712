export default function Main(){
    return (
      <>
        <div className="hero" style={{display:"grid",placeItems:"center",height:"100vh",background:"#000"}}>
          <div style={{textAlign:"center"}}>
            <h1 style={{color:"#fff",marginBottom: "20px",fontSize:"60px"}}>Site is on Maintenance</h1>
          </div>
        </div>
      </>
    )
  }