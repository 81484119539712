export const POST_SOCIAL_REQUEST = "POST_SOCIAL_REQUEST";
export const POST_SOCIAL_SUCCESS = "POST_SOCIAL_SUCCESS";
export const POST_SOCIAL_FALIURE = "POST_SOCIAL_FALIURE";

export const GET_SOCIAL_REQUEST = "GET_SOCIAL_REQUEST";
export const GET_SOCIAL_SUCCESS = "GET_SOCIAL_SUCCESS";
export const GET_SOCIAL_FALIURE = "GET_SOCIAL_FALIURE";

export const DELETE_SOCIAL_REQUEST = "DELETE_SOCIAL_REQUEST";
export const DELETE_SOCIAL_SUCCESS = "DELETE_SOCIAL_SUCCESS";
export const DELETE_SOCIAL_FALIURE = "DELETE_SOCIAL_FALIURE";

export const UPDATE_SOCIAL_REQUEST = "UPDATE_SOCIAL_REQUEST";
export const UPDATE_SOCIAL_SUCCESS = "UPDATE_SOCIAL_SUCCESS";
export const UPDATE_SOCIAL_FALIURE = "UPDATE_SOCIAL_FALIURE";