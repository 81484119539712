export const FETCH_PROSTHETICSAIDS_REQUEST = "FETCH_PROSTHETICSAIDS_REQUEST";
export const FETCH_PROSTHETICSAIDS_SUCCESS = "FETCH_PROSTHETICSAIDS_SUCCESS";
export const FETCH_PROSTHETICSAIDS_FALIURE = "FETCH_PROSTHETICSAIDS_FALIURE";

export const POST_PROSTHETICSAIDS_REQUEST = "POST_PROSTHETICSAIDS_REQUEST";
export const POST_PROSTHETICSAIDS_SUCCESS = "POST_PROSTHETICSAIDS_SUCCESS";
export const POST_PROSTHETICSAIDS_FALIURE = "POST_PROSTHETICSAIDS_FALIURE";

export const DELETE_PROSTHETICSAIDS_REQUEST = "DELETE_PROSTHETICSAIDS_REQUEST";
export const DELETE_PROSTHETICSAIDS_SUCCESS = "DELETE_PROSTHETICSAIDS_SUCCESS";
export const DELETE_PROSTHETICSAIDS_FALIURE = "DELETE_PROSTHETICSAIDS_FALIURE";

export const UPDATE_PROSTHETICSAIDS_REQUEST = "UPDATE_PROSTHETICSAIDS_REQUEST";
export const UPDATE_PROSTHETICSAIDS_SUCCESS = "UPDATE_PROSTHETICSAIDS_SUCCESS";
export const UPDATE_PROSTHETICSAIDS_FALIURE = "UPDATE_PROSTHETICSAIDS_FALIURE";