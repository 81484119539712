export const FETCH_PROVIDERNAMES_REQUEST = "FETCH_PROVIDERNAMES_REQUEST";
export const FETCH_PROVIDERNAMES_SUCCESS = "FETCH_PROVIDERNAMES_SUCCESS";
export const FETCH_PROVIDERNAMES_FALIURE = "FETCH_PROVIDERNAMES_FALIURE";

export const FETCH_DOCUMENTTYPE_REQUEST = "FETCH_DOCUMENTTYPE_REQUEST";
export const FETCH_DOCUMENTTYPE_SUCCESS = "FETCH_DOCUMENTTYPE_SUCCESS";
export const FETCH_DOCUMENTTYPE_FALIURE = "FETCH_DOCUMENTTYPE_FALIURE";

export const POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FALIURE = "POST_DOCUMENT_FALIURE";

export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FALIURE = "GET_DOCUMENT_FALIURE";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FALIURE = "DELETE_DOCUMENT_FALIURE";

export const UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FALIURE = "UPDATE_DOCUMENT_FALIURE";

export const DOWNLOAD_DOCUMENT_REQUEST = "DOWNLOAD_DOCUMENT_REQUEST";
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCUMENT_FALIURE = "DOWNLOAD_DOCUMENT_FALIURE";
