export const FETCH_NURSESNOTES_REQUEST = "FETCH_NURSESNOTES_REQUEST";
export const FETCH_NURSESNOTES_SUCCESS = "FETCH_NURSESNOTES_SUCCESS";
export const FETCH_NURSESNOTES_FALIURE = "FETCH_NURSESNOTES_FALIURE";

export const POST_NURSESNOTES_REQUEST = "POST_NURSESNOTES_REQUEST";
export const POST_NURSESNOTES_SUCCESS = "POST_NURSESNOTES_SUCCESS";
export const POST_NURSESNOTES_FALIURE = "POST_NURSESNOTES_FALIURE";

export const DELETE_NURSESNOTES_REQUEST = "DELETE_NURSESNOTES_REQUEST";
export const DELETE_NURSESNOTES_SUCCESS = "DELETE_NURSESNOTES_SUCCESS";
export const DELETE_NURSESNOTES_FALIURE = "DELETE_NURSESNOTES_FALIURE";

export const UPDATE_NURSESNOTES_REQUEST = "UPDATE_NURSESNOTES_REQUEST";
export const UPDATE_NURSESNOTES_SUCCESS = "UPDATE_NURSESNOTES_SUCCESS";
export const UPDATE_NURSESNOTES_FALIURE = "UPDATE_NURSESNOTES_FALIURE";