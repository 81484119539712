export const SIGNUP = "register";
export const SIGNIN = "signIn";
export const GETUSER = "getUser";
export const REFFWENAMES = "patientDemographicRefferNames";
export const DEMOGRAPHIC = "patientDemographic";
export const DIAGNOSIS = "patientDiagnosis";
export const DAIGNOSISMASTER = "masterData";
export const POSTALLERGIES = "patientAllergies";
export const GETALLERGIES = "patientAllergies";
export const GETALLERGIES_REACTION = "allergiesReaction";
export const GETALLERGIES_SEVERTIES = "allergiesSeverities";
export const DELETE_ALLERGIES_SEVERTIES = "patientAllergies";
export const UPDATE_ALLERGIES_SEVERTIES = "patientAllergies";

export const FAMILY_HISTORY = "patientfamilyHistory";
export const FAMILY_HISTORY_TYPE = "familyType";
export const DOCUMENT = "patientDocument"; 
export const VITALS = "patientVital";
export const OXYGEN_SUPPLY = "getmasterDataOxygenSupply";
export const DEPARTMENTS = "department";
export const COURSES = "course";
export const STUDENT = "student";
export const SCHOOL = "school";
export const FORGOT_PASSWORD = "forgotPaasword";
export const CHANGE_PASSWORD = "resetpassword";
export const CREATE_PASSWORD = "create-password";
export const PATIENTALLINFORMATION ="allPatientData";
export const CHECK_EMAIL ="checkemail";
export const SOCIAL_HISTORY ="patientSocialHistory";
export const ASSESSMENTTOOL ="assessmentTool";
export const ASSESSMENTGROUP ="assessmentGroup";
export const ASSIGNMENT ="assignment";
export const MYASSIGNMENT ="myassignment";
export const MYASSIGNMENTDETAIL ="myAssignmentDetail";
export const MYASSIGNMENTSTATUS ="myAssignmentStatus";
export const ASSIGNMENTSUBMISSIONDATE ="updateAssignmnetSubmissionDate";
export const CUSTOM_FORM = "customForm"
export const ASSESSMENTTYPE = "assessmentType"
export const GRADING = "grading"
export const GRADING_FORM_DATA = "gradingFormData"
export const CHECKCUSTOMFORM = "checkCustomFormExist"
export const GETSINGLECUSTOMFORM = "singleCustomForm"
export const UPDATEQUICKGUIDEATTACHEMENT = "updateQuickGuideAttachement"
export const NOTIFICATION ="notification"
export const MYCUSTOMASSIGNMENTFORM = "myCustomAssignmentForm"
export const SUBMITCUSTOMFORM = "submitCustomForm"
export const QUICKGUIDE ="quickguide"
export const MyGRADES ="myGrades"
export const PATIENTVITAL ="patientVitalData";
export const MEDICATION ="medication"
export const MEDICATIONMEDICINE ="medicationMedicine"
export const MEDICATION_FORM_DATA ="medicationFormData"
export const CUSTOMMASTERDATA ="addMasterData"
export const DASHBOARD ="getDashboard"
export const GETNURSINGCAREPLAN ="patientNursingcarePlan"
export const POSTNURSINGCAREPLAN ="patientNursingcarePlan"
export const UPDATENURSINGCAREPLAN ="patientNursingcarePlan"
export const DELETENURSINGCAREPLAN ="patientNursingcarePlan"
export const GETNURSESNOTES ="patientNursesNotes"
export const POSTNURSESNOTES ="patientNursesNotes"
export const UPDATENURSESNOTES ="patientNursesNotes"
export const DELETENURSESNOTES ="patientNursesNotes"
export const GETLABORATORY="patientLaboratory"
export const POSTLABORATORY="patientLaboratory"
export const UPDATELABORATORY="patientLaboratory"
export const DELETELABORATORY="patientLaboratory"
export const GETTHERAPY="patientTherapy"
export const POSTTHERAPY="patientTherapy"
export const UPDATETHERAPY="patientTherapy"
export const DELETETHERAPY="patientTherapy"
export const GETDOCTORORDER="doctorsOrders"
export const POSTDOCTORORDER="doctorsOrders"
export const UPDATEDOCTORORDER="doctorsOrders"
export const DELETEDOCTORORDER="doctorsOrders"
export const GETPROSTHETICSAIDS="prosthetics_Aids"
export const POSTPROSTHETICSAIDS="prosthetics_Aids"
export const UPDATEPROSTHETICSAIDS="prosthetics_Aids"
export const DELETEPROSTHETICSAIDS="prosthetics_Aids"