export const POST_SCHOOL_REQUEST = "POST_SCHOOL_REQUEST";
export const POST_SCHOOL_SUCCESS = "POST_SCHOOL_SUCCESS";
export const POST_SCHOOL_FALIURE = "POST_SCHOOL_FALIURE";

export const GET_SCHOOL_REQUEST = "GET_SCHOOL_REQUEST";
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS";
export const GET_SCHOOL_FALIURE = "GET_SCHOOL_FALIURE";

export const DELETE_SCHOOL_REQUEST = "DELETE_SCHOOL_REQUEST";
export const DELETE_SCHOOL_SUCCESS = "DELETE_SCHOOL_SUCCESS";
export const DELETE_SCHOOL_FALIURE = "DELETE_SCHOOL_FALIURE"


export const UPDATE_SCHOOL_REQUEST = "UPDATE_SCHOOL_REQUEST";
export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS";
export const UPDATE_SCHOOL_FALIURE = "UPDATE_SCHOOL_FALIURE";


export const CHECK__SCHOOL_EMAIL_REQUEST = "CHECK__SCHOOL_EMAIL_REQUEST";
export const CHECK__SCHOOL_EMAIL_FALIURE = "CHECK__SCHOOL_EMAIL_FALIURE";
export const CHECK_SCHOOL_EMAIL_SUCCESS = "CHECK_SCHOOL_EMAIL_SUCCESS";