
import NotFound from "../../assets/not-found.gif"
const NotFoundPage = () => {
    return (
        <div className='not_found'>
            <img src={NotFound} alt="" />
        </div>
    )
}

export default NotFoundPage