export const POST_ASSIGNMENT_REQUEST = "POST_ASSIGNMENT_REQUEST";
export const POST_ASSIGNMENT_SUCCESS = "POST_ASSIGNMENT_SUCCESS";
export const POST_ASSIGNMENT_FALIURE = "POST_ASSIGNMENT_FALIURE";

export const GET_ASSIGNMENT_REQUEST = "GET_ASSIGNMENT_REQUEST";
export const GET_ASSIGNMENT_SUCCESS = "GET_ASSIGNMENT_SUCCESS";
export const GET_ASSIGNMENT_FALIURE = "GET_ASSIGNMENT_FALIURE";

export const DELETE_ASSIGNMENT_REQUEST = "DELETE_ASSIGNMENT_REQUEST";
export const DELETE_ASSIGNMENT_SUCCESS = "DELETE_ASSIGNMENT_SUCCESS";
export const DELETE_ASSIGNMENT_FALIURE = "DELETE_ASSIGNMENT_FALIURE";

export const UPDATE_ASSIGNMENT_REQUEST = "UPDATE_ASSIGNMENT_REQUEST";
export const UPDATE_ASSIGNMENT_SUCCESS = "UPDATE_ASSIGNMENT_SUCCESS";
export const UPDATE_ASSIGNMENT_FALIURE = "UPDATE_ASSIGNMENT_FALIURE";