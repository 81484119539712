export const FETCH_THERAPY_REQUEST = "FETCH_THERAPY_REQUEST";
export const FETCH_THERAPY_SUCCESS = "FETCH_THERAPY_SUCCESS";
export const FETCH_THERAPY_FALIURE = "FETCH_THERAPY_FALIURE";

export const POST_THERAPY_REQUEST = "POST_THERAPY_REQUEST";
export const POST_THERAPY_SUCCESS = "POST_THERAPY_SUCCESS";
export const POST_THERAPY_FALIURE = "POST_THERAPY_FALIURE";

export const DELETE_THERAPY_REQUEST = "DELETE_THERAPY_REQUEST";
export const DELETE_THERAPY_SUCCESS = "DELETE_THERAPY_SUCCESS";
export const DELETE_THERAPY_FALIURE = "DELETE_THERAPY_FALIURE";

export const UPDATE_THERAPY_REQUEST = "UPDATE_THERAPY_REQUEST";
export const UPDATE_THERAPY_SUCCESS = "UPDATE_THERAPY_SUCCESS";
export const UPDATE_THERAPY_FALIURE = "UPDATE_THERAPY_FALIURE";