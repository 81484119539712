export const GET_MYASSIGNMENT_REQUEST = "GET_MYASSIGNMENT_REQUEST";
export const GET_MYASSIGNMENT_SUCCESS = "GET_MYASSIGNMENT_SUCCESS";
export const GET_MYASSIGNMENT_FALIURE = "GET_MYASSIGNMENT_FALIURE";

export const GET_MYASSIGNMENTDETAILS_REQUEST = "GET_MYASSIGNMENTDETAILS_REQUEST";
export const GET_MYASSIGNMENTDETAILS_SUCCESS = "GET_MYASSIGNMENTDETAILS_SUCCESS";
export const GET_MYASSIGNMENTDETAILS_FALIURE = "GET_MYASSIGNMENTDETAILS_FALIURE";


export const GET_MYASSIGNMENTSTATUS_REQUEST = "GET_MYASSIGNMENTSTATUS_REQUEST";
export const GET_MYASSIGNMENTSTATUS_SUCCESS = "GET_MYASSIGNMENTSTATUS_SUCCESS";
export const GET_MYASSIGNMENTSTATUS_FALIURE = "GET_MYASSIGNMENTSTATUS_FALIURE";

export const POST_MYASSIGNMENTSTATUS_REQUEST = "POST_MYASSIGNMENTSTATUS_REQUEST";
export const POST_MYASSIGNMENTSTATUS_SUCCESS = "POST_MYASSIGNMENTSTATUS_SUCCESS";
export const POST_MYASSIGNMENTSTATUS_FALIURE = "POST_MYASSIGNMENTSTATUS_FALIURE";

export const UPDATE_MYASSIGNMENTSTATUS_REQUEST = "UPDATE_MYASSIGNMENTSTATUS_REQUEST";
export const UPDATE_MYASSIGNMENTSTATUS_SUCCESS = "UPDATE_MYASSIGNMENTSTATUS_SUCCESS";
export const UPDATE_MYASSIGNMENTSTATUS_FALIURE = "UPDATE_MYASSIGNMENTSTATUS_FALIURE";

export const GET_MYCUSTOMASSIGNMENTFORM_REQUEST = "GET_MYCUSTOMASSIGNMENTFORM_REQUEST";
export const GET_MYCUSTOMASSIGNMENTFORM_SUCCESS = "GET_MYCUSTOMASSIGNMENTFORM_SUCCESS";
export const GET_MYCUSTOMASSIGNMENTFORM_FALIURE = "GET_MYCUSTOMASSIGNMENTFORM_FALIURE";

export const GET_MYGRADES_REQUEST = "GET_MYGRADES_REQUEST";
export const GET_MYGRADES_SUCCESS = "GET_MYGRADES_SUCCESS";
export const GET_MYGRADES_FALIURE = "GET_MYGRADES_FALIURE";


export const UPDATE_ASSIGNMENTSUBMISSIONDATE_REQUEST = "UPDATE_ASSIGNMENTSUBMISSIONDATE_REQUEST";
export const UPDATE_ASSIGNMENTSUBMISSIONDATE_SUCCESS = "UPDATE_ASSIGNMENTSUBMISSIONDATE_SUCCESS";
export const UPDATE_ASSIGNMENTSUBMISSIONDATE_FALIURE = "UPDATE_ASSIGNMENTSUBMISSIONDATE_FALIURE";
