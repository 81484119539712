export const POST_ASSESSMENTTOOL_REQUEST = "POST_ASSESSMENTTOOL_REQUEST";
export const POST_ASSESSMENTTOOL_SUCCESS = "POST_ASSESSMENTTOOL_SUCCESS";
export const POST_ASSESSMENTTOOL_FALIURE = "POST_ASSESSMENTTOOL_FALIURE";

export const GET_ASSESSMENTTOOL_REQUEST = "GET_ASSESSMENTTOOL_REQUEST";
export const GET_ASSESSMENTTOOL_SUCCESS = "GET_ASSESSMENTTOOL_SUCCESS";
export const GET_ASSESSMENTTOOL_FALIURE = "GET_ASSESSMENTTOOL_FALIURE";

export const DELETE_ASSESSMENTTOOL_REQUEST = "DELETE_ASSESSMENTTOOL_REQUEST";
export const DELETE_ASSESSMENTTOOL_SUCCESS = "DELETE_ASSESSMENTTOOL_SUCCESS";
export const DELETE_ASSESSMENTTOOL_FALIURE = "DELETE_ASSESSMENTTOOL_FALIURE";

export const UPDATE_ASSESSMENTTOOL_REQUEST = "UPDATE_ASSESSMENTTOOL_REQUEST";
export const UPDATE_ASSESSMENTTOOL_SUCCESS = "UPDATE_ASSESSMENTTOOL_SUCCESS";
export const UPDATE_ASSESSMENTTOOL_FALIURE = "UPDATE_ASSESSMENTTOOL_FALIURE";


export const GET_ASSESSMENTTYPE_REQUEST = "GET_ASSESSMENTTYPE_REQUEST";
export const GET_ASSESSMENTTYPE_SUCCESS = "GET_ASSESSMENTTYPE_SUCCESS";
export const GET_ASSESSMENTTYPE_FALIURE = "GET_ASSESSMENTTYPE_FALIURE";

export const POST_ASSESSMENTTYPE_REQUEST = "POST_ASSESSMENTTYPE_REQUEST";
export const POST_ASSESSMENTTYPE_SUCCESS = "POST_ASSESSMENTTYPE_SUCCESS";
export const POST_ASSESSMENTTYPE_FALIURE = "POST_ASSESSMENTTYPE_FALIURE";
export const EDIT_CUSTOM_FORM_ASSESSMENT_FORM ="EDIT_CUSTOM_FORM_ASSESSMENT_FORM"

export const SHOW_ASSESSMENT_FORM ="SHOW_ASSESSMENT_FORM"