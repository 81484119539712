export const FETCH_LABORATORY_REQUEST = "FETCH_LABORATORY_REQUEST";
export const FETCH_LABORATORY_SUCCESS = "FETCH_LABORATORY_SUCCESS";
export const FETCH_LABORATORY_FALIURE = "FETCH_LABORATORY_FALIURE";

export const POST_LABORATORY_REQUEST = "POST_LABORATORY_REQUEST";
export const POST_LABORATORY_SUCCESS = "POST_LABORATORY_SUCCESS";
export const POST_LABORATORY_FALIURE = "POST_LABORATORY_FALIURE";

export const DELETE_LABORATORY_REQUEST = "DELETE_LABORATORY_REQUEST";
export const DELETE_LABORATORY_SUCCESS = "DELETE_LABORATORY_SUCCESS";
export const DELETE_LABORATORY_FALIURE = "DELETE_LABORATORY_FALIURE";

export const UPDATE_LABORATORY_REQUEST = "UPDATE_LABORATORY_REQUEST";
export const UPDATE_LABORATORY_SUCCESS = "UPDATE_LABORATORY_SUCCESS";
export const UPDATE_LABORATORY_FALIURE = "UPDATE_LABORATORY_FALIURE";