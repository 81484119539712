export const FETCH_NURSINGCAREPLAN_REQUEST = "FETCH_NURSINGCAREPLAN_REQUEST";
export const FETCH_NURSINGCAREPLAN_SUCCESS = "FETCH_NURSINGCAREPLAN_SUCCESS";
export const FETCH_NURSINGCAREPLAN_FALIURE = "FETCH_NURSINGCAREPLAN_FALIURE";

export const POST_NURSINGCAREPLAN_REQUEST = "POST_NURSINGCAREPLAN_REQUEST";
export const POST_NURSINGCAREPLAN_SUCCESS = "POST_NURSINGCAREPLAN_SUCCESS";
export const POST_NURSINGCAREPLAN_FALIURE = "POST_NURSINGCAREPLAN_FALIURE";

export const DELETE_NURSINGCAREPLAN_REQUEST = "DELETE_NURSINGCAREPLAN_REQUEST";
export const DELETE_NURSINGCAREPLAN_SUCCESS = "DELETE_NURSINGCAREPLAN_SUCCESS";
export const DELETE_NURSINGCAREPLAN_FALIURE = "DELETE_NURSINGCAREPLAN_FALIURE";

export const UPDATE_NURSINGCAREPLAN_REQUEST = "UPDATE_NURSINGCAREPLAN_REQUEST";
export const UPDATE_NURSINGCAREPLAN_SUCCESS = "UPDATE_NURSINGCAREPLAN_SUCCESS";
export const UPDATE_NURSINGCAREPLAN_FALIURE = "UPDATE_NURSINGCAREPLAN_FALIURE";