export const FETCH_REFFERNAMES_REQUEST = "FETCH_REFFERNAMES_REQUEST";
export const FETCH_REFFERNAMES_SUCCESS = "FETCH_REFFERNAMES_SUCCESS";
export const FETCH_REFFERNAMES_FALIURE = "FETCH_REFFERNAMES_FALIURE";

export const POST_DEMOGRAPHIC_REQUEST = "POST_DEMOGRAPHIC_REQUEST";
export const POST_DEMOGRAPHIC_SUCCESS = "POST_DEMOGRAPHIC_SUCCESS";
export const POST_DEMOGRAPHIC_FALIURE = "POST_DEMOGRAPHIC_FALIURE";

export const GET_DEMOGRAPHIC_REQUEST = "GET_DEMOGRAPHIC_REQUEST";
export const GET_DEMOGRAPHIC_SUCCESS = "GET_DEMOGRAPHIC_SUCCESS";
export const GET_DEMOGRAPHIC_FALIURE = "GET_DEMOGRAPHIC_FALIURE";

export const UPDATE_DEMOGRAPHIC_REQUEST = "UPDATE_DEMOGRAPHIC_REQUEST";
export const UPDATE_DEMOGRAPHIC_SUCCESS = "UPDATE_DEMOGRAPHIC_SUCCESS";
export const UPDATE_DEMOGRAPHIC_FALIURE = "UPDATE_DEMOGRAPHIC_FALIURE";

export const DELETE_DEMOGRAPHIC_REQUEST = "DELETE_DEMOGRAPHIC_REQUEST";
export const DELETE_DEMOGRAPHIC_SUCCESS = "DELETE_DEMOGRAPHIC_SUCCESS";
export const DELETE_DEMOGRAPHIC_FALIURE = "DELETE_DEMOGRAPHIC_FALIURE";

export const CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FALIURE = "CHECK_EMAIL_FALIURE";