export const POST_VITALS_REQUEST = "POST_VITALS_REQUEST";
export const POST_VITALS_SUCCESS = "POST_VITALS_SUCCESS";
export const POST_VITALS_FALIURE = "POST_VITALS_FALIURE";

export const GET_VITALS_REQUEST = "GET_VITALS_REQUEST";
export const GET_VITALS_SUCCESS = "GET_VITALS_SUCCESS";
export const GET_VITALS_FALIURE = "GET_VITALS_FALIURE";

export const DELETE_VITALS_REQUEST = "DELETE_VITALS_REQUEST";
export const DELETE_VITALS_SUCCESS = "DELETE_VITALS_SUCCESS";
export const DELETE_VITALS_FALIURE = "DELETE_VITALS_FALIURE";

export const UPDATE_VITALS_REQUEST = "UPDATE_VITALS_REQUEST";
export const UPDATE_VITALS_SUCCESS = "UPDATE_VITALS_SUCCESS";
export const UPDATE_VITALS_FALIURE = "UPDATE_VITALS_FALIURE";

export const GET_OXYGEN_SUPPLY_REQUEST = "GET_OXYGEN_SUPPLY_REQUEST";
export const GET_OXYGEN_SUPPLY_SUCCESS = "GET_OXYGEN_SUPPLY_SUCCESS";
export const GET_OXYGEN_SUPPLY_FALIURE = "GET_OXYGEN_SUPPLY_FALIURE";