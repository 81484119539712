export const FETCH_DOCTORORDER_REQUEST = "FETCH_DOCTORORDER_REQUEST";
export const FETCH_DOCTORORDER_SUCCESS = "FETCH_DOCTORORDER_SUCCESS";
export const FETCH_DOCTORORDER_FALIURE = "FETCH_DOCTORORDER_FALIURE";

export const POST_DOCTORORDER_REQUEST = "POST_DOCTORORDER_REQUEST";
export const POST_DOCTORORDER_SUCCESS = "POST_DOCTORORDER_SUCCESS";
export const POST_DOCTORORDER_FALIURE = "POST_DOCTORORDER_FALIURE";

export const DELETE_DOCTORORDER_REQUEST = "DELETE_DOCTORORDER_REQUEST";
export const DELETE_DOCTORORDER_SUCCESS = "DELETE_DOCTORORDER_SUCCESS";
export const DELETE_DOCTORORDER_FALIURE = "DELETE_DOCTORORDER_FALIURE";

export const UPDATE_DOCTORORDER_REQUEST = "UPDATE_DOCTORORDER_REQUEST";
export const UPDATE_DOCTORORDER_SUCCESS = "UPDATE_DOCTORORDER_SUCCESS";
export const UPDATE_DOCTORORDER_FALIURE = "UPDATE_DOCTORORDER_FALIURE";