export const POST_GRADING_REQUEST = "POST_GRADING_REQUEST";
export const POST_GRADING_SUCCESS = "POST_GRADING_SUCCESS";
export const POST_GRADING_FALIURE = "POST_GRADING_FALIURE";

export const GET_GRADING_REQUEST = "GET_GRADING_REQUEST";
export const GET_GRADING_SUCCESS = "GET_GRADING_SUCCESS";
export const GET_GRADING_FALIURE = "GET_GRADING_FALIURE";

export const GET_FORM_DATA_REQUEST = "GET_FORM_DATA_REQUEST";
export const GET_FORM_DATA_SUCCESS = "GET_FORM_DATA_SUCCESS";
export const GET_FORM_DATA_FALIURE = "GET_FORM_DATA_FALIURE";

export const DELETE_GRADING_REQUEST = "DELETE_GRADING_REQUEST";
export const DELETE_GRADING_SUCCESS = "DELETE_GRADING_SUCCESS";
export const DELETE_GRADING_FALIURE = "DELETE_GRADING_FALIURE";

export const UPDATE_GRADING_REQUEST = "UPDATE_GRADING_REQUEST";
export const UPDATE_GRADING_SUCCESS = "UPDATE_GRADING_SUCCESS";
export const UPDATE_GRADING_FALIURE = "UPDATE_GRADING_FALIURE";