
export const FETCH_SIGNUP_REQUEST = "FETCH_SIGNUP_REQUEST";
export const FETCH_SIGNUP_SUCCESS = "FETCH_SIGNUP_SUCCESS";
export const FETCH_SIGNUP_FALIURE = "FETCH_SIGNUP_FALIURE";

export const FETCH_SIGNIN_REQUEST = "FETCH_SIGNIN_REQUEST";
export const FETCH_SIGNIN_SUCCESS = "FETCH_SIGNIN_SUCCESS";
export const FETCH_SIGNIN_FALIURE = "FETCH_SIGNIN_FALIURE";

export const FETCH__SCHOOL_SIGNIN_REQUEST = "FETCH__SCHOOL_SIGNIN_REQUEST";
export const FETCH__SCHOOL_SIGNIN_SUCCESS = "FETCH__SCHOOL_SIGNIN_SUCCESS";
export const FETCH__SCHOOL_SIGNIN_FALIURE = "FETCH__SCHOOL_SIGNIN_FALIURE";

export const FETCH__STUDENT_SIGNIN_REQUEST = "FETCH__STUDENT_SIGNIN_REQUEST";
export const FETCH__STUDENT_SIGNIN_SUCCESS = "FETCH__STUDENT_SIGNIN_SUCCESS";
export const FETCH__STUDENT_SIGNIN_FALIURE = "FETCH__STUDENT_SIGNIN_FALIURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FALIURE = "FORGOT_PASSWORD_FALIURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FALIURE = "CHANGE_PASSWORD_FALIURE";

export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST";
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_FALIURE = "CREATE_PASSWORD_FALIURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

