export const POST_QUICK_GUIDE_REQUEST = "POST_QUICK_GUIDE_REQUEST";
export const POST_QUICK_GUIDE_SUCCESS = "POST_QUICK_GUIDE_SUCCESS";
export const POST_QUICK_GUIDE_FALIURE = "POST_QUICK_GUIDE_FALIURE";

export const GET_QUICK_GUIDE_REQUEST = "GET_QUICK_GUIDE_REQUEST";
export const GET_QUICK_GUIDE_SUCCESS = "GET_QUICK_GUIDE_SUCCESS";
export const GET_QUICK_GUIDE_FALIURE = "GET_QUICK_GUIDE_FALIURE";

export const DELETE_QUICK_GUIDE_REQUEST = "DELETE_QUICK_GUIDE_REQUEST";
export const DELETE_QUICK_GUIDE_SUCCESS = "DELETE_QUICK_GUIDE_SUCCESS";
export const DELETE_QUICK_GUIDE_FALIURE = "DELETE_QUICK_GUIDE_FALIURE"


export const UPDATE_QUICK_GUIDE_REQUEST = "UPDATE_QUICK_GUIDE_REQUEST";
export const UPDATE_QUICK_GUIDE_SUCCESS = "UPDATE_QUICK_GUIDE_SUCCESS";
export const UPDATE_QUICK_GUIDE_FALIURE = "UPDATE_QUICK_GUIDE_FALIURE";
